import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OktaAuthenticationAdapter } from './adapters/okta-authentication.adapter';
import { AuthenticationAdapter } from './authentication.adapter';
import { AuthenticationService } from './authentication.service';

@NgModule({
  imports: [CommonModule, RouterModule],
  providers: [
    {
      provide: AuthenticationAdapter,
      useClass: OktaAuthenticationAdapter,
    },
    AuthenticationService,
  ],
})
export class SharedUtilsAuthenticationModule {}
